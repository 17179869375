/* eslint-disable */
const {cookies, cookiesConsent, environment} = require('../constants-enumerate');
var inAppRegex = /academyInApp/;
var suppressPixels = (environment.IN_APP || inAppRegex.exec(location.search) !== null || navigator?.globalPrivacyControl);
import Cookies from 'js-cookie';

if (Cookies.get(cookiesConsent.ANALYTICS) === undefined) {
  Cookies.set(cookiesConsent.ANALYTICS, 'true', cookies.CONFIG);
}
if (Cookies.get(cookiesConsent.TARGETING) === undefined) {
  Cookies.set(cookiesConsent.TARGETING, 'true', cookies.CONFIG);
}

if (!Cookies.get(cookies.WDIO_SUPPRESS_PIXELS) && !suppressPixels && Cookies.get(cookiesConsent.TARGETING) !== 'true') {
  /**
   *
   * Facebook Analytics Pixel Limited Data Use
   */
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
  fbq('dataProcessingOptions', ['LDU']);
  fbq('init', '274976323413910');
  fbq('track', 'PageView');
}

if (!Cookies.get(cookies.WDIO_SUPPRESS_PIXELS) && !suppressPixels && Cookies.get(cookiesConsent.ANALYTICS) === 'true' && Cookies.get(cookiesConsent.TARGETING) === 'true') {
  /**
   *
   * Facebook Analytics Pixel
   */
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
    n.callMethod.apply(n,arguments):n.queue.push(arguments)};
    if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
    n.queue=[];t=b.createElement(e);t.async=!0;
    t.src=v;s=b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t,s)}(window, document,'script',
    'https://connect.facebook.net/en_US/fbevents.js');
  fbq('dataProcessingOptions', []);
  fbq('init', '274976323413910');
  fbq('track', 'PageView');

  /**
   *
   * Google Tag Manager
   */
   (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-5TKXJFC');

   const bodyTag = document.getElementsByTagName('body')[0];
   const noScript = document.createElement('noscript');
   const gtmIframe = document.createElement('iframe');
   gtmIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-5TKXJFC';
   gtmIframe.height = '0';
   gtmIframe.width = '0';
   gtmIframe.style = 'display:none;visibility:hidden';
   bodyTag.appendChild(noScript);
   noScript.appendChild(gtmIframe);

   window.dataLayer = window.dataLayer || [];
   function gtag(){dataLayer.push(arguments);};
   gtag('consent', 'update', {
    'ad_storage': 'granted',
    'analytics_storage': 'granted'
   });

  /**
   *
   * Google Search and Display
   */
   var googleAds = document.createElement('script');
   googleAds.src ='https://www.googletagmanager.com/gtag/js?id=AW-756955732';
   googleAds.async = true;
   document.body.appendChild(googleAds);

   window.dataLayer = window.dataLayer || [];
   function gtag1(){dataLayer.push(arguments);}
   gtag1("js", new Date());
   gtag1("config", "AW-782124098");

  /**
   *
   * Bing Pixel
   */
  (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"18003324"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");

  /**
   *
   * ABCmouseJS Pixel
   */
  window.lightboxjs || function (c) { function g(b, d) { d && (d += (/\?/.test(d) ? "&" : "?") + "lv=1"); c[b] || function () { var i = window, h = document, j = b, g = h.location.protocol, l = "load", k = 0; (function () { function b() { a.P(l); a.w = 1; c[j]("_load") } c[j] = function () { function m() { m.id = e; return c[j].apply(m, arguments) } var b, e = ++k; b = this && this != i ? this.id || 0 : 0; (a.s = a.s || []).push([e, b, arguments]); m.then = function (b, c, h) { var d = a.fh[e] = a.fh[e] || [], j = a.eh[e] = a.eh[e] || [], f = a.ph[e] = a.ph[e] || []; b && d.push(b); c && j.push(c); h && f.push(h); return m }; return m }; var a = c[j]._ = {}; a.fh = {}; a.eh = {}; a.ph = {}; a.l = d ? d.replace(/^\/\//, (g == "https:" ? g : "http:") + "//") : d; a.p = { 0: +new Date }; a.P = function (b) { a.p[b] = new Date - a.p[0] }; a.w && b(); i.addEventListener ? i.addEventListener(l, b, !1) : i.attachEvent("on" + l, b); var q = function () { function b() { return ["<head></head><", c, ' onload="var d=', n, ";d.getElementsByTagName('head')[0].", d, "(d.", g, "('script')).", i, "='", a.l, "'\"></", c, ">"].join("") } var c = "body", e = h[c]; if (!e) return setTimeout(q, 100); a.P(1); var d = "appendChild", g = "createElement",
  i = "src", k = h[g]("div"), l = k[d](h[g]("div")), f = h[g]("iframe"), n = "document", p; k.style.display = "none"; e.insertBefore(k, e.firstChild).id = o + "-" + j; f.frameBorder = "0"; f.id = o + "-frame-" + j; /MSIE[ ]+6/.test(navigator.userAgent) && (f[i] = "javascript:false"); f.allowTransparency = "true"; l[d](f); try { f.contentWindow[n].open() } catch (s) { a.domain = h.domain, p = "javascript:var d=" + n + ".open();d.domain='" + h.domain + "';", f[i] = p + "void(0);" } try { var r = f.contentWindow[n]; r.write(b()); r.close() } catch (t) { f[i] = p + 'd.write("' + b().replace(/"/g, String.fromCharCode(92) + '"') + '");d.close();' } a.P(2) }; a.l && q() })() }(); c[b].lv = "1"; return c[b] } var o = "lightboxjs", k = window[o] = g(o); k.require = g; k.modules = c }({}); /*]]>*/
  window.lightboxlib = lightboxjs.require("lightboxlib", "//www.lightboxcdn.com/vendor/196f5c18-dcc0-48c6-8d0f-0dcb14017390/lightbox.js?mb=" + (new Date().getTime()));
  if(window.ga) {
    window.ga(function() {
      var puCheckWaitMsecs = 10000;	//10 secs
      window.setTimeout( function() {
        if(document.readyState === 'complete' && !window.DIGIOH_API) {
          ga('send', {
            hitType: 'event',
            eventCategory: 'PostUp',
            eventAction: 'PostUp Inactive',
            eventLabel: puCheckWaitMsecs.toString(),
            nonInteraction: 1
          });
        }
      }, puCheckWaitMsecs);
    });
  }
}
